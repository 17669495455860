export default {
  namespaced: true,
  state: {
    adminInfo: null,
	uniqueAuth: [],
  },
  mutations: {
    adminInfo(state, adminInfo) {
      state.adminInfo = adminInfo;
    },
	uniqueAuth(state, uniqueAuth) {
	  state.uniqueAuth = uniqueAuth;
	},
  },
  actions: {
    
  },
};
