import app from './modules/app';
import article from './modules/article';
import setting from './modules/setting';
import finance from './modules/finance';

import system from './modules/system';
import company from './modules/company';
import index from './modules/index';
import frameOut from './modules/frameOut';
import settings from '@/setting';
import LayoutMain from '@/layout';
let routePre = settings.routePre;

const frameIn = [
	{
	  path: '/',
	  meta: {
	    title: 'demo',
	  },
	  redirect: {
	    name: 'home_index',
	  },
	  component: LayoutMain,
	  children: [
	    // 刷新页面 必须保留
	    {
	      path: 'refresh',
	      name: 'refresh',
	      hidden: true,
	      component: {
	        beforeRouteEnter(to, from, next) {
	          next((instance) => instance.$router.replace(from.fullPath));
	        },
	        render: (h) => h(),
	      },
	    },
	    // 页面重定向 必须保留
	    {
	      path: 'redirect/:route*',
	      name: 'redirect',
	      hidden: true,
	      component: {
	        beforeRouteEnter(to, from, next) {
	          next((instance) => instance.$router.replace(JSON.parse(from.params.route)));
	        },
	        render: (h) => h(),
	      },
	    },
	  ],
	},
	{
	  path: routePre,
	  meta: {
	    title: 'CRMEB',
	  },
	  redirect: {
	    name: 'home_index',
	  },
	  component: LayoutMain,
	},
	{
	  path: routePre + '/widget.images/index.html',
	  name: `images`,
	  meta: {
	    //auth: ['admin-user-user-index'],
	    title: '上传图片',
	  },
	  component: () => import('@/components/uploadPictures/widgetImg'),
	},
	app,
	article,
	setting,
	finance,
	system,
	company,
	index
];


/**
 * 在主框架之外显示
 */

const frameOuts = frameOut;



/**
 * 错误页面
 */

const errorPage = [
  {
    path: routePre + '/403',
    name: '403',
    meta: {
      title: '403',
    },
    component: () => import('@/pages/system/error/403'),
  },
  {
    path: routePre + '/500',
    name: '500',
    meta: {
      title: '500',
    },
    component: () => import('@/pages/system/error/500'),
  },
  {
    path: routePre + '/:error*',
    name: '404',
    meta: {
      title: '404',
    },
    component: () => import('@/pages/system/error/404'),
  },
];



// 导出需要显示菜单的
export const frameInRoutes = frameIn;


export default [...frameIn,...frameOuts,...errorPage];