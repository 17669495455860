

import LayoutMain from '@/layout';
import setting from '@/setting';
let routePre = setting.routePre;

const meta = {
  auth: true,
};

const pre = 'setting_';

export default {
  path: routePre + '/setting',
  name: 'setting',
  header: 'setting',
  redirect: {
    name: `${pre}systemRole`,
  },
  component: LayoutMain,
  children: [
	// {
	//   path: 'config',
	//   name: `${pre}config`,
	//   meta: {
	//     auth: ['admin-setting-config'],
	//     title: '系统配置',
	//   },
	//   component: () => import('@/pages/setting/config/index'),
	// },
	
	{
	  path: 'config/:type?/:tab_id?',
	  name: `${pre}setApp`,
	  meta: {
	    title: '系统设置',
	  },
	  component: () => import('@/pages/setting/setSystem/index'),
	},
	
	
    {
      path: 'auth/role',
      name: `${pre}systemRole`,
      meta: {
        auth: ['setting-auth-role'],
        title: '角色管理',
      },
      component: () => import('@/pages/setting/auth/role/index'),
    },
	
	{
	  path: 'auth/admin',
	  name: `${pre}systemAdmin`,
	  meta: {
	    auth: ['setting-auth-admin'],
	    title: '管理员管理',
	  },
	  component: () => import('@/pages/setting/auth/admin/index'),
	},
	
	{
	  path: 'auth/menus',
	  name: `${pre}systemMenus`,
	  meta: {
	    auth: ['setting-auth-menus'],
	    title: '权限规则',
	  },
	  component: () => import('@/pages/setting/auth/menus/index'),
	},
	
	{
	  path: 'storage',
	  name: `${pre}storage`,
	  meta: {
	    auth: ['setting-storage'],
	    title: '储存配置',
	  },
	  component: () => import('@/pages/setting/storage'),
	},
	
	{
	  path: 'city',
	  name: `${pre}dada`,
	  meta: {
	    auth: ['setting-city'],
	    title: '城市数据',
	  },
	  component: () => import('@/pages/setting/cityDada/index'),
	},
	
	{
	  path: 'other_config/sms/:type?/:tab_id?',
	  name: `${pre}other_sms`,
	  meta: {
	    auth: ['setting-other-sms'],
	    title: '短信功能配置',
	  },
	  component: () => import('@/pages/setting/setSystem/index'),
	},
	{
	  path: 'other_config/pay/:type?/:tab_id?',
	  name: `${pre}other_pay`,
	  meta: {
	    auth: ['setting-other-pay'],
	    title: '支付功能配置',
	  },
	  component: () => import('@/pages/setting/setSystem/index'),
	},
	// {
	//   path: 'system_menus/index',
	//   name: `${pre}systemMenus`,
	//   meta: {
	//     auth: ['setting-system-menus'],
	//     title: '权限规则',
	//   },
	//   component: () => import('@/pages/setting/systemMenus/index'),
	// },
  ],
};
