

import LayoutMain from '@/layout';
import setting from '@/setting';
let routePre = setting.routePre;

const meta = {
  auth: true,
};

const pre = 'finance_';

export default {
  path: routePre + '/finance',
  name: 'finance',
  header: 'finance',
  redirect: {
    name: `${pre}index`,
  },
  component: LayoutMain,
  children: [
	
	
	{
	  path: 'recharge',
	  name: `${pre}recharge`,
	  meta: {
	    title: '充值记录',
	  },
	  component: () => import('@/pages/finance/recharge/index'),
	},
	{
	  path: 'order',
	  name: `${pre}order`,
	  meta: {
	    title: '订单管理',
	  },
	  component: () => import('@/pages/finance/order/index'),
	},
	{
	  path: 'invoice',
	  name: `${pre}invoice`,
	  meta: {
	    title: '发票管理',
	  },
	  component: () => import('@/pages/finance/invoice/index'),
	}
  ],
};
