

import LayoutMain from '@/layout';
import setting from '@/setting';
let routePre = setting.routePre;

const meta = {
  auth: true,
};

const pre = 'app_';

export default {
  path: routePre + '/app',
  name: 'app',
  header: 'app',
  redirect: {
    name: `${pre}systemRole`,
  },
  component: LayoutMain,
  children: [
	{
	  path: 'wechat',
	  name: `${pre}wechat`,
	  meta: {
	    auth: ['app-wechat'],
	    title: '公众号',
	  },
	  component: () => import('@/pages/setting/config/index'),
	},
	
	
  ],
};
