

import LayoutMain from '@/layout';
import setting from '@/setting';
let routePre = setting.routePre;

const meta = {
  auth: true,
};

const pre = 'article_';

export default {
  path: routePre + '/article',
  name: 'article',
  header: 'article',
  redirect: {
    name: `${pre}notice`,
  },
  component: LayoutMain,
  children: [
	
	{
	  path: 'notice',
	  name: `${pre}notice`,
	  meta: {
	    auth: ['article-notice'],
	    title: '公告管理',
	  },
	  component: () => import('@/pages/article/notice'),
	},
	
	{
	  path: 'notice/update',
	  name: `${pre}noticeUpdate`,
	  meta: {
	    auth: ['article-notice'],
	    title: '编辑公告',
		activeMenu: routePre + '/article/notice',
	  },
	  component: () => import('@/pages/article/notice/update'),
	},
	
	{
	  path: 'help',
	  name: `${pre}help`,
	  meta: {
	    auth: ['article-help'],
	    title: '帮助文档',
	  },
	  component: () => import('@/pages/article/help'),
	},
	
	{
	  path: 'help/update',
	  name: `${pre}helpUpdate`,
	  meta: {
	    auth: ['article-help'],
	    title: '编辑帮助文档',
		activeMenu: routePre + '/article/help',
	  },
	  component: () => import('@/pages/article/help/update'),
	},
  ],
};
