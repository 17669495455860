

import LayoutMain from '@/layout';
import setting from '@/setting';
let routePre = setting.routePre;

const meta = {
  auth: true,
};

const pre = 'system_';

export default {
  path: routePre + '/system',
  name: 'system',
  header: 'system',
  redirect: {
    name: `${pre}menus`,
  },
  component: LayoutMain,
  children: [
	{
	  path: 'menus/index',
	  name: `${pre}menus`,
	  meta: {
	    auth: ['system-menus'],
	    title: '权限维护',
	  },
	  component: () => import('@/pages/system/menus/index'),
	},
	{
	  path: 'config/group',
	  name: `${pre}group`,
	  meta: {
	    auth: ['system-config-group'],
	    title: '组合数据',
	  },
	  component: () => import('@/pages/system/group/index'),
	},
	{
	  path: 'config/group/list/:id?',
	  name: `${pre}groupList`,
	  meta: {
	    auth: ['system-config-group'],
	    title: '组合数据列表',
	    activeMenu: routePre + '/system/config/group',
	  },
	  component: () => import('@/pages/system/group/list'),
	},
	{
	  path: 'config/tab',
	  name: `${pre}configTab`,
	  meta: {
	    auth: ['system-config-configTab'],
	    title: '配置分类',
	  },
	  component: () => import('@/pages/system/configTab/index'),
	},
	{
	  path: 'config/tab/list/:id?',
	  name: `${pre}configTabList`,
	  meta: {
	    auth: ['system-config-configTab'],
	    title: '配置列表',
		activeMenu: routePre + "/system/config/tab"
	  },
	  component: () => import('@/pages/system/configTab/list'),
	},
	
	{
	  path: 'maintain/databackup',
	  name: `${pre}databackup`,
	  meta: {
	    auth: ['system-maintain-databackup'],
	    title: '数据备份',
	  },
	  component: () => import('@/pages/system/maintain/databackup/index'),
	},
	
	
	{
	  path: 'code_generation',
	  name: `${pre}code_generation`,
	  meta: {
	    //auth: ['system-config-code-generation'],
	    title: '代码生成',
	  },
	  component: () => import('@/pages/system/codeGeneration/index'),
	},
	
	{
	  path: 'backend_routing',
	  name: `${pre}backend_routing`,
	  meta: {
	    auth: ['system-backend_routing'],
	    title: '接口管理',
	  },
	  component: () => import('@/pages/system/backendRouting/index'),
	},
  ],
};
